import React from "react"
import Layout from "../components/Layout"

const Terms = () => {
  return (
    <Layout>
      <div className="max-w-4xl flex-grow mx-auto flex flex-col justify-around px-3 lg:px-0">
        <h1 className="text-3xl py-4">TÉRMINOS Y CONDICIONES DE USO APP</h1>
        <ol className="list-decimal list-inside lg:list-outside">
          <li className='pb-2'>Estos Términos y Condiciones de Uso regulan las reglas a que se sujeta la utilización de la APP RECUPERATUSPUNTOS (en adelante, la APP), que puede descargarse desde el dominio http://recuperatuspuntos.app. La descarga o utilización de la APP atribuye la condición de Usuario a quien lo haga e implica la aceptación de todas las condiciones incluidas en este documento y en la Política de Privacidad y el Aviso Legal de dicha página Web. El Usuario debería leer estas condiciones cada vez que utilice la APP, ya que podrían ser modificadas en lo sucesivo.</li>
          <li className='pb-2'>Únicamente los Usuarios expresamente autorizados por GIA 2020 S.L. podrán acceder a la descarga y uso de la APP. Los Usuarios que no dispongan de autorización, no podrán acceder a dicho contenido.</li>
          <li className='pb-2'>
            <div className='pb-2'>Cargos: eres responsable del pago de todos los costes o gastos en los que incurras como resultado de descargar y usar la Aplicación de GIA 2020 S.L., incluido cualquier cargo de red de operador o itinerancia. Consulta con tu proveedor de servicios los detalles al respecto.</div>
            <div className='pb-2'>El derecho al desistimiento quedará anulado una vez el usuario haya hecho uso y/o cumplimentado más de 2 test dentro de la App.</div>
          </li>
          <li className='pb-2'>Estadísticas anónimas: GIA 2020 S.L. se reserva el derecho a realizar un seguimiento de tu actividad en la Aplicación de y a informar de ello a nuestros proveedores de servicios estadísticos de terceros. Todo ello de forma anónima.</li>
          <li className='pb-2'>Protección de tu información personal: queremos ayudarte a llevar a cabo todos los pasos necesarios para proteger tu privacidad e información. Consulta la Política de privacidad de GIA 2020 S.L. y los avisos sobre privacidad de la Aplicación para conocer qué tipo de información recopilamos y las medidas que tomamos para proteger tu información personal.</li>
          <li className='pb-2'>Queda prohibido alterar o modificar ninguna parte de la APP a de los contenidas de la misma, eludir, desactivar o manipular de cualquier otra forma (o tratar de eludir, desactivar o manipular) las funciones de seguridad u otras funciones del programa y utilizar la APP o sus contenidos para un fin comercial o publicitario. Queda prohibido el uso de la APP con la finalidad de lesionar bienes, derechos o intereses de GIA 2020 S.L. o de terceros. Queda igualmente prohibido realizar cualquier otro uso que altere, dañe o inutilice las redes, servidores, equipos, productos y programas informáticos de GIA 2020 S.L. o de terceros.</li>
          <li className='pb-2'>
            <div className='pb-2'>La APP y sus contenidos (textos, fotografías, gráficos, imágenes, tecnología, software, links, contenidos, diseño gráfico, código fuente, etc.), así como las marcas y demás signos distintivos son propiedad de GIA 2020 S.L. o de terceros, no adquiriendo el Usuario ningún derecho sobre ellos por el mero uso de la APP. El Usuario, deberá abstenerse de:</div>
            <ul className='list-disc pl-4'>
              <li className='pb-2'>Reproducir, copiar, distribuir, poner a disposición de terceros, comunicar públicamente, transformar o modificar la APP o sus contenidos, salvo en los casos contemplados en la ley o expresamente autorizados por GIA 2020 S.L. o por el titular de dichos derechos.</li>
              <li className='pb-2'>Reproducir o copiar para uso privado la APP o sus contenidos, así como comunicarlos públicamente o ponerlos a disposición de terceros cuando ello conlleve su reproducción.</li>
              <li className='pb-2'>Extraer o reutilizar todo o parte sustancial de los contenidos integrantes de la APP.</li>
            </ul>
          </li>
          <li className='pb-2'>
            <div className='pb-2'>GIA 2020 S.L., le otorga una licencia revocable, no exclusiva, intransferible y limitada para descargar, instalar y usar la Aplicación estrictamente de acuerdo con los términos de este Acuerdo.</div>
            <div className='pb-2'>La licencia que le otorga GIA 2020 S.L., es únicamente para sus propósitos personales, no comerciales, estrictamente de acuerdo con los términos de este Acuerdo.</div>
            <div className='pb-2'>Dicha licencia se concede también en los mismos términos con respecto a las actualizaciones y mejoras que se realizarán en la aplicación. Dichas licencias de uso podrán ser revocadas por GIA 2020 S.L. unilateralmente en cualquier momento, mediante la mera notificación al Usuario.</div>
          </li>
          <li className='pb-2'>
            <div className='pb-2'>Este Acuerdo permanecerá en vigor hasta que Usted o GIA 2020 S.L., lo den por terminado. GIA 2020 S.L., puede, a su entera discreción, en cualquier momento y por cualquier motivo o sin él, suspender o rescindir este acuerdo con o sin previo aviso.</div>
            <div className='pb-2'>Este acuerdo terminará inmediatamente, sin previo aviso de GIA 2020 S.L., en caso de que no cumpla con los Términos y condiciones, o con alguna de las disposiciones de este acuerdo. También puede rescindir este acuerdo eliminando la Aplicación y todas las copias de la misma de su Dispositivo o de su computadora.</div>
            <div className='pb-2'>Tras la terminación de este Acuerdo, deberá dejar de usar la Aplicación, y eliminar todas las copias de la Aplicación de su Dispositivo, y no le será devuelto ningún importe cobrado, siempre y cuando se hayan realizado más de dos Test dentro de la Aplicación.</div>
            <div className='pb-2'>La terminación de este Acuerdo no limitará ninguno de los derechos o recursos de GIA 2020 S.L., por ley o en equidad en caso de incumplimiento por su parte (durante la vigencia de este Acuerdo) de cualquiera de sus obligaciones en virtud del presente Acuerdo.</div>
          </li>
          <li className='pb-2'>
            <div className='pb-2'>La Aplicación puede mostrar, incluir o poner a disposición contenido de terceros (incluidos datos, información, aplicaciones y otros servicios de productos) o proporcionar enlaces a sitios web o servicios de terceros.</div>
            <div className='pb-2'>Usted reconoce y acepta que GIA 2020 S.L. no será responsable de los Servicios de terceros, incluida su precisión, integridad, puntualidad, validez, cumplimiento de los derechos de autor, legalidad, decencia, calidad o cualquier otro aspecto de los mismos. GIA 2020 S.L. no asume y no tendrá ninguna obligación o responsabilidad ante usted o cualquier otra persona o entidad por los Servicios de terceros.</div>
            <div className='pb-2'>Debe cumplir con los Términos de acuerdo de Terceros aplicables cuando utilice la Aplicación. Los Servicios de terceros y los enlaces a los mismos se brindan únicamente para su conveniencia y usted accede a ellos y los usa completamente bajo su propio riesgo y sujeto y sujeto a los Términos y condiciones de dichos terceros.</div>
            <div className='pb-2'>Corresponde al Usuario, en todo caso, disponer de herramientas adecuadas para la detección y desinfección de programas maliciosos o cualquier otro elemento informático dañino. GIA 2020 S.L. no se responsabiliza de los daños producidos a equipos informáticos durante el uso de la APP. Igualmente, GIA 2020 S.L. no será responsable de los daños producidos a los Usuarios cuando dichos daños tengan su origen en fallos o desconexiones en las redes de telecomunicaciones que interrumpan el servicio.</div>
          </li>
          <li className='pb-2'>
            <div className='pb-2'>La Aplicación se le proporciona "TAL CUAL" y "SEGÚN DISPONIBILIDAD" y con todas las fallas y defectos sin garantía de ningún tipo. En la medida máxima permitida por la ley aplicable, GIA 2020 S.L., en su propio nombre y en nombre de sus afiliadas y sus respectivos licenciantes y proveedores de servicios, renuncia expresamente a todas las garantías, ya sean expresas, implícitas, estatutarias o de otro tipo, con respecto a la Aplicación, incluidas todas las garantías implícitas de comerciabilidad, idoneidad para un propósito particular, título y no infracción, y garantías que puedan surgir del curso de la negociación, el curso del desempeño, el uso o la práctica comercial. Sin limitación a lo anterior, GIA 2020 S.L. no ofrece garantía ni compromiso, y no hace ninguna representación de ningún tipo de que la Aplicación cumplirá con sus requisitos.</div>
            <div className='pb-2'>Sin perjuicio de lo anterior, ni GIA 2020 S.L. ni ninguno de los proveedores de GIA 2020 S.L. hacen ninguna representación o garantía de ningún tipo, expresa o implícita: (i) en cuanto al funcionamiento o disponibilidad de la Aplicación, o la información, contenido y materiales o productos. Incluido en el mismo; (ii) que la Aplicación será ininterrumpida o libre de errores; (iii) en cuanto a la precisión, confiabilidad o vigencia de cualquier información o contenido proporcionado a través de la Aplicación; o (iv) que la Aplicación, sus servidores, el contenido o los correos electrónicos enviados desde o en nombre de GIA 2020 S.L. están libres de virus, scripts, troyanos, gusanos, malware, bombas de tiempo u otros componentes dañinos.</div>
            <div className='pb-2'>Algunas jurisdicciones no permiten la exclusión de ciertos tipos de garantías o limitaciones sobre los derechos legales aplicables de un consumidor, por lo que algunas o todas las exclusiones y limitaciones anteriores pueden no aplicarse a usted. Pero en tal caso, las exclusiones y limitaciones establecidas en esta sección se aplicarán en la mayor medida exigible según la ley aplicable. En la medida en que exista una garantía bajo la ley que no pueda ser rechazada, GIA 2020 S.L. será la única responsable de dicha garantía.</div>
          </li>
          <li className='pb-2'>
            <div className='pb-2'>Sin perjuicio de los daños en los que pueda incurrir, la responsabilidad total de GIA 2020 S.L. y cualquiera de sus proveedores en virtud de cualquier disposición de este Acuerdo y su recurso exclusivo para todo lo anterior se limitará a la cantidad realmente pagada por Usted por la Aplicación o/a través de la Aplicación o 100 USD si no ha comprado nada a través de la Aplicación.</div>
            <div className='pb-2'>En la medida máxima permitida por la ley aplicable, en ningún caso GIA 2020 S.L. o sus proveedores serán responsables de ningún daño especial, incidental, indirecto o consecuente de ningún tipo (incluidos, entre otros, daños por lucro cesante, pérdida de datos o otra información, por interrupción del negocio, por lesiones personales, pérdida de privacidad que surja de o de alguna manera relacionada con el uso o la imposibilidad de usar la Aplicación, software de terceros y / o hardware de terceros utilizado con la Aplicación, o de lo contrario en relación con cualquier disposición de este Acuerdo), incluso si GIA 2020 S.L. o cualquier proveedor han sido informados de la posibilidad de tales daños e incluso si el recurso no cumple su propósito esencial.</div>
            <div className='pb-2'>Algunos estados / jurisdicciones no permiten la exclusión o limitación de daños incidentales o consecuentes, por lo que es posible que la limitación o exclusión anterior no se aplique en su caso.</div>
          </li>
          <li className='pb-2'>Si alguna disposición de este Acuerdo se considera inaplicable o inválida, dicha disposición se cambiará e interpretará para lograr los objetivos de dicha disposición en la mayor medida posible según la ley aplicable y las disposiciones restantes continuarán en pleno vigor y efecto.</li>
          <li className='pb-2'>Salvo lo dispuesto en el presente, el hecho de no ejercer un derecho o exigir el cumplimiento de una obligación en virtud de este Acuerdo no afectará la capacidad de una de las partes para ejercer dicho derecho o requerir dicho cumplimiento en cualquier momento posterior, ni constituirá una renuncia la renuncia a una infracción de cualquier incumplimiento posterior.</li>
          <li className='pb-2'>La Compañía no ofrece ninguna garantía con respecto a la Aplicación.</li>
          <li className='pb-2'>IMPORTANTE: Podemos, sin que esto suponga ninguna obligación contigo, modificar estas Condiciones de uso sin avisar en cualquier momento. Si continúas utilizando la aplicación una vez realizada cualquier modificación en estas Condiciones de uso, esa utilización continuada constituirá la aceptación por tu parte de tales modificaciones. Si no aceptas estas condiciones de uso ni aceptas quedar sujeto a ellas, no debes utilizar la aplicación ni descargar o utilizar cualquier software relacionado. El uso que hagas de la aplicación queda bajo tu única responsabilidad. No tenemos responsabilidad alguna por la eliminación o la incapacidad de almacenar o transmitir cualquier contenido u otra información mantenida o transmitida por la aplicación. No somos responsables de la precisión o la fiabilidad de cualquier información o consejo transmitidos a través de la aplicación. Podemos, en cualquier momento, limitar o interrumpir tu uso a nuestra única discreción. Hasta el máximo que permite la ley, en ningún caso seremos responsables por cualquier pérdida o daño relacionados.</li>
          <li className='pb-2'>El Usuario se compromete a hacer un uso correcto de la APP, de conformidad con la Ley, con los presentes Términos y Condiciones de Uso y con las demás reglamentos e instrucciones que, en su caso, pudieran ser de aplicación El Usuario responderá frente a GIA 2020 S.L. y frente a terceros de cualesquiera daños o perjuicios que pudieran causarse por incumplimiento de estas obligaciones.</li>
          <li className='pb-2'>Estos Términos y Condiciones de Uso se rigen íntegramente por la legislación española. Para la resolución de cualquier conflicto relativo a su interpretación o aplicación, el Usuario se somete expresamente a la jurisdicción de los tribunales de Elche (Alicante), España.</li>
          <li className='pb-2'>El precio de la suscripción será de 15'99€ en la Play Store y de 17'99€ en la Apple Store y se podrá realizar mediante los métodos de pago configurados en cada plataforma.</li>
        </ol> 

        <h1 className="text-2xl py-4">Contáctenos</h1>

        <p>Si tiene alguna pregunta sobre este Acuerdo, puede comunicarse con nosotros por correo electrónico: <a href="mailto:apprecuperatuspuntos@gmail.com" className="underline ">apprecuperatuspuntos@gmail.com</a></p>
      </div>
    </Layout>
  )
}

export default Terms